.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	left:24%;
	top: 65%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	right:26%;
	bottom: 38%;
}
.scenario_lock_3 {
	height: 3rem;
	width: fit-content;
	left:0%;
	bottom: 30%;
}

.scenario_image_1{
	height: 3rem;
	width: fit-content;
	top: 34%;
	right:14%;
}