.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	right: 49%;
	top: 60%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	left: 7%;
	bottom: 30%;
}

.scenario_image_1 {
	height: 3rem;
	width: fit-content;
	top: 50%;
	right: 80%;
}
