.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	left:37%;
	bottom: 26%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	right:5%;
	bottom: 31%;
}

.scenario_image_1{
	height: 3rem;
	width: fit-content;
	bottom: 27%;
	right:12%;
}
.scenario_image_2{
	height: 3rem;
	width: fit-content;
	top:32%;
	right:35%;
}