
.button {
    padding: 12px;
    border: none;
    background-color: #18673D;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-bottom: 1rem;
    cursor: pointer;
}
.suport{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    bottom: 2%;
    right: 2%;
    width: 3%;
    padding: 0.5rem;
    aspect-ratio: 1/1;
    background: #18673d;
    cursor: pointer;
}
.signup{
    font-size: 0.8rem;
    background-color: #3fad72;

}
.suport:hover{
    transform: scale(1.1);
}
.button:hover {
    background-color: #238f55;
}