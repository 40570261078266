.backdrop {
	z-index: 1;
	display: flex;
	width: 100vw !important;
	height: 100vh !important;
	top: 0;
	left: 0;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.233);
}
.modal {
	width: 40%;
	height: 60%;
	z-index: 1;
	background: white;
	position: absolute;
	border-radius: 1rem;
	overflow: hidden;
	.btn {
		cursor: pointer;
		font-family: 'Exo 2', sans-serif;
		font-weight: bold;
		font-size: 1.3rem;
		color: white;
		padding: 0.5rem 2rem;
		border: none;
		background-color: #18673d;
		border-bottom: 0.3rem solid #64c72a;
		border-radius: 0.5rem;
		&.clue {
			font-size: 0.8rem;
			padding: 0.3rem 1.5rem;
			
			color: black;
			border: none;
			background-color: #d2d438;
			border-bottom: 0.3rem solid #abad10;
			border-radius: 0.5rem;
			margin-top: 1rem;
		}
	}
	.btn:hover {
		&.clue {
			
			background-color: #64c72a;
		border-bottom: 0.3rem solid #18673d;
		}
		background-color: #64c72a;
		border-bottom: 0.3rem solid #18673d;
	}
	.closeModal {
		font-size: 1.3rem;
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: white;
		cursor: pointer;
	}
	.closeModal:hover {
		color: red;
	}
	.wrapperImage{
		height: 100%;
		width: 100%;
		.carosselWrapper {
			width: calc(100% - 2rem);
			height: 100%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0px 1rem;
			gap: 0.5rem;
			background-color: #392510;
			.invisible {
				color: transparent !important;
				cursor: default;
			}
			svg {
				cursor: pointer;
				color: white;
			}
			.imgNumber {
				position: absolute;
				top: 1%;
				left: 2%;
				margin: 0;
				color: white;
			}
			.img {
				// width: 90%;
				height: 95%;
			}
		}
	}
	
	.lock {
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		
		.imgWrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			// padding: 1rem;
			background-color: white;
			width: 80%;
			height: 100%;
			.image {
				width: 100%;
				height: 100%;
			}
		}

		.contentWrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 40%;
			height: 100%;
			background-color: #392510;
			color: white;
			padding: 0px 2rem;
			.upsideDiv {
				height: 50%;
				width: 100%;
			}
			.bottom {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: space-around;
				height: 50%;
				width: 100%;
				.inputWrapper {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 1rem;
					text-align: center;
				}
			}
			input {
				padding: 0.5rem;
				border: none;
				border-radius: 0.4rem;
				background-color: var(--gray-200);
				height: 2rem;
			}
			input[type='number']::-webkit-outer-spin-button,
			input[type='number']::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
	}
	.switchWrapper {
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		.imgWrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			// padding: 1rem;
			background-color: white;
			width: 50%;
			height: 100%;
		}
		.switchs {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: #392510;
			width: 50%;
			height: 100%;
			.switchWrapper {
				display: grid;
				height: 50%;
				width: 100%;
				place-items: center;
				justify-content: center;
				justify-items: center;
				align-items: center;
				grid-template-columns: 1fr 1fr;
				// gap: 10px;

				.switch {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 1.3rem;
				}
			}
		}
	}
	.image {
		display: flex;
		flex-direction: column;
		position: relative;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		height: calc(100% - 2rem);
		width: 100%;
		background-color: #392510;
		padding: 1rem 0px;
		* {
			max-height: 100%;
			max-width: 100%;
		}
	}
}
