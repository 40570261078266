.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	left: 20%;
	top: 50%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	left: 7.5%;
	bottom: 10%;
}
.scenario_lock_3 {
	height: 3rem;
	width: fit-content;
	right: 14%;
	bottom: 36%;
}

.scenario_image_1 {
	height: 3rem;
	width: fit-content;
	top: 34%;
	right: 47%;
}