.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	right:25%;
	top: 50%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	left:14.5%;
	top:37%;
}
.scenario_lock_3 {
	height: 3rem;
	width: fit-content;
	left:30%;
	top: 50%;
}

.scenario_image_1{
	height: 3rem;
	width: fit-content;
	bottom: 20%;
	right:29%;
}