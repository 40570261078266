*::-webkit-scrollbar {
	height: 0.5rem !important;
}
.wrapper{
	display: flex;
	background-color: #18673d;
	width: 100%;
	height: 15%;
	padding: 2rem 1rem;
	font-family: 'Exo 2', sans-serif;
	border-radius: 1rem;
	margin-bottom: 1rem;
	gap: 0.5rem;
	.homeBtn{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding-right: 0.5rem;
		border-right: 1px solid white;
		svg{
			cursor: pointer;
		}
		svg:hover{
			transform: scale(1.05);
		}
	}
}
.stepper {
	display: flex;
	align-self: center;
	align-items: center;
	width: 100%;
	height: 80%;
	justify-self: flex-end;
	padding: 2rem 1rem;
	gap: 1rem;
	border-radius: 1rem;
	margin-bottom: 1rem;
	overflow-x: auto;
	overflow-y: hidden;
	
	&.mobile {
		padding: 0.4rem;
		gap: 0.4rem;
		border-radius: 0.4rem;
		font-size: 0.6rem;
		.step {
			border-radius: 0.4rem;
			padding: 0.4rem;
			.header {
				display: flex;
				gap: 0.1rem;
				.checkbox {
					width: 0.8rem;
					height: 0.8rem;
					border-radius: 0.2rem;
				}
			}
			.hexagon {
				height: 2.8rem;
				width: 3.4rem;
			}
		}
	}
	
	.step {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		background-color: #218c53;
		border-radius: 1rem;
		padding: 1rem;
		cursor: auto;
		gap: 0.5rem;
		
		&.active {
			background-color: #ffcc00 !important;
		}
		&.show {
			cursor: pointer;
		}
		&.show:hover {
			background-color: #26b669;
			transform: scale(1.05);
		}
		.header {
			display: flex;
			gap: 0.5rem;
			height: 20%;
			width: 100%;
			justify-content: center;
			text-wrap: nowrap;
			.checkbox {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				aspect-ratio: 1/1;
				background-color: white;
				border-radius: 0.2rem;
				svg {
					width: 80%;
					height: 80%;
				}
			}
			span {
				align-self: center;
				color: white;
				font-size: 0.8rem;
			}
		}
		.hexagon {
			position: relative;
			height: 100%;
			aspect-ratio: 1.3/1;
			clip-path: polygon(50% -50%, 100% 50%, 50% 150%, 0 50%);
			overflow: hidden;
			background-color: white;
			.finished{
				.checkImg{
					position: absolute;
					z-index: 2;
				}
				img{
					-webkit-filter: grayscale(100%);
					filter: grayscale(100%);
					opacity: 0.6;
				}
			}
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				height: 100%;
				width: auto;
				transform: translate(-50%, -50%);
				object-fit: cover;
			}
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				height: 80%;
				width: 30%;
				transform: translate(-50%, -50%);
				object-fit: cover;
			}
		}
	}
}
