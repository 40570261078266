
.avatarsWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
    margin-bottom: 1rem;
    .avatar {
        cursor: pointer;
        transition: border 0.3s;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    
    .selected {
        border: 2px solid #18673D;
        border-radius: 50%;
    }
}
