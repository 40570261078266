.complaints {
	width: 100%;
	height: 100%;
	display: flex;
	font-size: 1.2rem;
	.left {
		height: 100%;
		width: 30%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		img {
			margin-top: 2rem;
			width: 30%;
		}
	}
	.body {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		width: 60%;
		justify-content: space-around;
		h1 {
			color: #18673d;
			&.green {
				color: #07c25e;
			}
		}
		span {
			
		}
	}
	.rigth {
		height: 100%;
		width: 30%;
		display: flex;
		gap: 1rem;
		img {
			margin-top: 10%;
			height: 20%;
		}
		.btnWrapper {
			display: flex;
			align-content: center;
			justify-content: flex-end;
			width: 70%;
			.btn {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-self: center;
				align-items: center;
				height: fit-content;
				width: fit-content;
				cursor: pointer;
				transition: all 0.5s ease-out;
				span {
					font-family: 'Exo 2', sans-serif;
					font-size: 1rem;
					font-style: italic;
					font-weight: bold;
				}
				
				img {
					height: 50px;
					width: 50px;
				}
			}
			.btn:hover{
				transform: scale(1.2);
			}
		}

	}
	.footer {
		display: flex;
		padding: 2rem;
		// gap: 2rem;
		width: 100%;
		justify-content: space-between;
		background-color: #07c25e;
        .infoWrapper{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .item{
                display: flex;
                align-items: center;
                gap: 0.5rem;
                svg{
                    font-size:1.2rem;
                    color: white;
                }
                span{
                    font-size: 1.2rem;
                }
            }
        }
		span {
			font-family: 'Exo 2', sans-serif;
			font-style: italic;
			font-weight: bold;
			color: white;
		}
        .qrCode{
            img{
                height: 100%;
            }
        }
	}
}
