.scenario_image_1{
	height: 3rem;
	width: fit-content;
	bottom: 23%;
	left:42.6%;
}

.scenario_image_2{
	height: 3rem;
	width: fit-content;
	bottom: 15%;
	right:42%;
}
.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	left:20%;
	top: 60%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	right:8%;
	bottom: 18%;
}		
