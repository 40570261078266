:root {
	--gray-50: #f8f8f8;
	--gray-100: #efefef;
	--gray-200: #dcdcdc;
	--gray-300: #bdbdbd;
	--gray-400: #989898;
	--gray-500: #7c7c7c;
	--gray-600: #656565;
	--gray-700: #525252;
	--gray-800: #464646;
	--gray-900: #3d3d3d;
	--gray-950: #292929;

	--primary-50: #eff9ff;
	--primary-100: #dbf0fe;
	--primary-200: #bee6ff;
	--primary-300: #92d7fe;
	--primary-400: #5ebffc;
	--primary-500: #39a1f8;
	--primary-600: #2383ed;
	--primary-700: #1c71e3;
	--primary-800: #1c58b1;
	--primary-900: #1d4b8b;
	--primary-950: #162f55;

	--success-50: #ebfef5;
	--success-100: #cefde5;
	--success-200: #a2f8d0;
	--success-300: #66efb9;
	--success-400: #2add9c;
	--success-500: #05c485;
	--success-600: #009f6d;
	--success-700: #00805a;
	--success-800: #006549;
	--success-900: #01533d;
	--success-950: #002f23;

	--danger-50: #fff1f1;
	--danger-100: #ffdfdf;
	--danger-200: #ffc5c5;
	--danger-300: #ff9d9d;
	--danger-400: #ff6464;
	--danger-500: #ff2727;
	--danger-600: #ed1515;
	--danger-700: #c80d0d;
	--danger-800: #a50f0f;
	--danger-900: #881414;
	--danger-950: #4b0404;

	--warning-50: #fafaec;
	--warning-100: #f4f3cd;
	--warning-200: #ebe49d;
	--warning-300: #dfd065;
	--warning-400: #d4bc3b;
	--warning-500: #c4a72e;
	--warning-600: #a98425;
	--warning-700: #876121;
	--warning-800: #714f22;
	--warning-900: #593d1f;
	--warning-950: #382310;	
}