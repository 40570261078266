.backdrop {
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw !important;
	height: 100vh !important;
	top: 0;
	left: 0;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.8);
	.modal {
		width: 40%;
		// height: 60%;
		padding: 2rem;
		z-index:4 !important;
		background: white;
		border-radius: 1rem;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		position: relative;
		gap: 1rem;
		font-family: 'Exo 2', sans-serif;
		font-size: 1.15rem;
		.closeModal {
			font-size: 1.3rem;
			position: absolute;
			top: 1rem;
			right: 1rem;
			color: black;
			cursor: pointer;
		}

        .body{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            color: black;
            align-items: center;
            h1{
                margin: 1rem;
            }
            .wrapper{
                display: flex;
                gap: 1rem;
            }

        }
		.closeModal:hover {
			color: red;
		}

		.btn {
			cursor: pointer;
			font-family: 'Exo 2', sans-serif;
			font-weight: bold;
			font-size: 1.3rem;
			color: white;
			padding: 0.5rem 2rem;
			border: none;
			background-color: #18673d;
			border-bottom: 0.3rem solid #64c72a;
			border-radius: 0.5rem;
		}

		.btn:hover {
			background-color: #64c72a;
			border-bottom: 0.3rem solid #18673d;
		}
	}
}
