.btn {
    position: absolute;
    bottom: 4%;
    left: 4%;
    cursor: pointer;
    transition: transform 0.2s ease-out;
}

.btn:hover {
    transform: scale(1.1);
}