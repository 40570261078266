.selectOptions {
    display: flex;
    justify-content: center;
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    background-color: #18673d;
    padding: 1rem;
    font-family: 'Exo 2', sans-serif;
    overflow: auto;
  
    .gameBody {
      display: flex;
      flex-direction: column;
      width: calc(90% - 4rem);
      max-height: calc(100% - 4rem);
      background-color: white;
      border-radius: 2rem;
      padding: 2rem;
      gap: 2rem;
      justify-content: space-between;
      span {
        align-self: center;
      }
      
      .optionsWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        max-height: 90%;
        overflow: auto;
        .option {
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 1.5rem; /* Aumenta o tamanho da letra */
        }
      }
  
      .btnWrapper {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: space-between;
        .disabled{
          background-color: rgb(163, 163, 163) !important;
          border-bottom: 0.3rem solid rgb(163, 163, 163) !important;
          cursor: unset !important;

        }
        button {
          cursor: pointer;
          font-family: 'Exo 2', sans-serif;
          font-weight: bold;
          font-size: 1.3rem;
          color: white;
          padding: 0.2rem 2rem;
          border: none;
          background-color: #18673d;
          border-bottom: 0.3rem solid #64c72a;
          border-radius: 0.5rem;
          transition: all 0.2s ease-in;
          &.red{
            background-color: #672818;
            border-bottom: 0.3rem solid #9b3405;
          }
        }

        
  
        button:hover {
          background-color: #64c72a;
          border-bottom: 0.3rem solid #18673d;
          &.red{
            background-color: #ca4523;
            border-bottom: 0.3rem solid #9b3405;
          }
        }
      }
    }
  }
  
  .customCheckbox {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee; 
      border-radius: 4px; 
    }

    input:checked + .checkmark {
      background-color: #2196F3; 
    }
  
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  
    input:checked + .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  