.body {
	display: flex;
	align-self: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Exo 2', sans-serif;
	width: 100%;
	height: calc(60% - 2rem);
	padding: 1rem 0rem;
	&.mobile {
		font-size: 0.6rem;
	}
	.header {
		display: flex;
		justify-content: space-between;
		height: 20%;
		width: 100%;
		h2 {
			font-size: 1.5rem;
			text-align: center;
			max-width: 50%;
			margin: 0 !important;
		}
		img {
			height: 70%;
		}
	}
	.menuBody {
		height: 70%;
		display: flex;
		gap: 3rem;
		.left {
			display: flex;
			flex-direction: column;
			height: 100%;
			align-items: center;
			justify-content: space-around;
			.guardian {
				height: 60%;
			}
			.tutorialContainer {
				display: inline-block;
				position: relative;
				cursor: pointer;
			
			}
			.tutorialContainer:hover{
				transform: scale(1.1);
			}
		}
		.wrapperBody {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 12px;
			height: 100%;
			font-size: 1.15rem;
			overflow: auto;

			h2 {
				text-align: center;
				align-self: center;
			}
			.italic {
				font-style: italic;
			}
		}
		.preGaming {
			display: flex;
			gap: 1rem;
			width: 100%;
			height: 100%;
			.left {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				gap: 0.5rem;
				width: 50%;
				max-height: 100%;

				.btn {
					width: fit-content;
					cursor: pointer;
					font-family: 'Exo 2', sans-serif;
					font-weight: bold;
					font-size: 1.8rem;
					color: white;
					padding: 0.5rem 2rem;
					border: none;
					background-color: #18673d;
					border-bottom: 0.3rem solid #64c72a;
					border-radius: 0.5rem;
					// max-height: 20%;
				}
				.btn:hover {
					background-color: #64c72a;
					border-bottom: 0.3rem solid #18673d;
				}
				img {
					border-radius: 1rem;
					// max-width: 100%;
					height: 60%;
				}
			}
			.rigth {
				height: 100%;
				width: 50%;
				
				.textWrapper {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					// text-align: center;
					max-height: 70%;
					overflow-x: auto;
					// width: calc(100% - 2rem);
					background-color: #f1f9ea;
					font-size: 1.15rem;
					border-radius: 1rem;
					box-shadow: 0 9px 16px rgba(0, 0, 0, 0.3);
					padding: 2rem 1rem;
					h3{
						text-align: center;
					}
				}
				
			}
			
			
		}
	}
}
