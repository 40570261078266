.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	right:27%;
	bottom: 38%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	left:42.5%;
	bottom: 18%;
}
.scenario_lock_3 {
	height: 3rem;
	width: fit-content;
	left:1%;
	bottom: 43%;
}

