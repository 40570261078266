.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	right:45%;
	top: 42%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	right:23%;
	top: 45%;
}
.scenario_lock_3 {
	height: 3rem;
	width: fit-content;
	right:10%;
	bottom: 34%;
}
.circle{
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 50%;
}
.red{
	background-color: red;
}
.blue{
	background-color: blue;
}
.green{
	background-color: green;
}
.orange{
	background-color: orange;
}