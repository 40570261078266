.activeAccount {
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
	align-items: center;
	font-family: 'Exo 2', sans-serif;
	border-radius: 12px;
	margin-bottom: 12px;
	.token {
		position: relative;
	}
	.form {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 400px;
		padding: 20px;
		background-color: white;
		border-radius: 8px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		.img {
			max-width: 50%;
			align-self: center;
		}
		.label {
			margin-bottom: 8px;
			font-weight: 500;
			color: var(--gray-700);
		}
		.input {
			width: 100%;
			padding: 8px;
			margin-bottom: 16px;
			border: 1px solid var(--gray-300);
			border-radius: 4px;
			font-size: 14px;
			color: var(--gray-800);
			box-sizing: border-box;
		}

		.button {
			width: 100%;
			padding: 12px;
			border: none;
			background-color: #18673d;
			color: white;
			font-size: 16px;
			font-weight: 500;
			border-radius: 4px;
			cursor: pointer;
			transition: background-color 0.3s ease;
			margin: 0.4rem 0px;
			&.back {
				background-color: #8a3324;
			}
		}

		.button:hover {
			background-color: #238f55;
			&.back {
				background-color: #cc5b48;
			}
		}
	}
}
