.scenario {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: var(--warning-900);
	overflow: hidden;
	.gameWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		height: 100%;
		position: relative;
		.locks{
			cursor: pointer;
			position: absolute;
		}
		.locks:hover{
			transform: scale(1.2);
		}
		img {
			user-select: none;
			width: auto;
			height: 95%;
		}
	}
	
	 
}
.loading{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100dvw;
	height: 100dvh;
	.spin{
		display: flex;
		height: 100%;
		align-self: center;
		justify-self: center;
	
	}
}

