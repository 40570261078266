.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	background-color: var(--gray-50);
	// font-family: 'Roboto', sans-serif;
	font-family: 'Exo 2', sans-serif;

	overflow-y: auto;
	border-radius: 12px;
	margin-bottom: 12px;
	&.mobile {
		margin: 2rem 2rem;
	}
	&.mobile .form {
		padding: 2rem !important;
		align-items: center;
	}
	overflow-x: hidden;
	.passwordWrapper{
		position: relative;
	}
	.icon{
		position: absolute;
		right: 8%;
		top: 17%;
		cursor: pointer;
	}
	.form {
		display: flex;
		overflow-y: auto;
		flex-direction: column;
		width: 100%;
		max-width: 400px;
		padding: 20px;
		background-color: white;
		border-radius: 8px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		img {
			display: flex;
			align-self: center;
			max-width: 80%;
		}
		.label {
			margin-bottom: 6px;
			font-weight: 500;
			color: var(--gray-700);
		}
		.passwordBar {
			width: calc(100% - 20px);
		}
		.input {
			width: calc(100% - 20px);
			padding: 8px;
			margin-bottom: 1rem;
			border: 1px solid var(--gray-300);
			border-radius: 4px;
			font-size: 14px;
			color: var(--gray-800);
			box-sizing: border-box;
		}

		.button {
			// margin-top: 6px;
			width: calc(100% - 20px);
			padding: 12px;
			border: none;
			background-color: #18673d;
			color: white;
			font-size: 16px;
			font-weight: 500;
			border-radius: 4px;
			cursor: pointer;
			transition: background-color 0.3s ease;
		}

		.button:hover {
			background-color: #238f55;
		}
		.select {
			width: calc(100% - 20px);
			background-color: transparent;
			border-radius: 5px;
			font-family: inherit;
			font-size: inherit;
			cursor: inherit;
			line-height: inherit;
			z-index: 1;
			padding: 6px 6px;
			margin-bottom: 1rem;
			&::-ms-expand {
				display: none;
			}
		}
	}

	// @media (max-width: 600px) {
	// 	.form {
	// 		margin-top: 2rem;
	// 		max-height: 80%;

	// 		width: 90%;
	// 		max-width: 100%;
	// 	}
	// }
}
