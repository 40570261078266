.tutorial {
	display: flex;
	// justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	font-family: 'Exo 2', sans-serif;
	width: 100%;
	background-color: #18673d;
	// padding: 1rem;
	gap: 2rem;
	overflow: auto;
	h3 {
        font-size: 1.4rem;
		color: white;
	}
	button {
        cursor: pointer;
        font-size: 1.2rem;
        font-style: italic;
		color: #18673d;
		font-family: 'Exo 2', sans-serif;
		font-weight: bold;
		border: none;
		padding: 0.5rem 2rem;
		background-color: white;
		border-radius: 0.4rem;
		border-bottom: 4px solid #64c72a;
	}
    button:hover{
		background-color: rgb(187, 182, 182);

    }
	.video{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 2rem;
		height: 85%;
		width: 80%;
		iframe{
			height: 100%;
			width: 100%;
		}
	}
}
