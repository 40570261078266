.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	right:10%;
	top: 65%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	right:37%;
	top:47%;
}
.scenario_lock_3 {
	height: 3rem;
	width: fit-content;
	left:17%;
	top: 50%;
}

.scenario_image_1{
	height: 3rem;
	width: fit-content;
	top: 47%;
	right:49%;
}