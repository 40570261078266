.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	left:14%;
	bottom: 42%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	right:2%;
	bottom: 22%;
}

.scenario_image_1{
	height: 3rem;
	width: fit-content;
	bottom: 12%;
	left: 36%;
}
.scenario_image_2{
	height: 3rem;
	width: fit-content;
	top:40%;
	right: 15%;
}