.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	left:14%;
	bottom: 42%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	right:2%;
	bottom: 22%;
}

.scenario_image_1{
	height: 3rem;
	width: fit-content;
	bottom: 35%;
	right:25%;
}
.scenario_image_2{
	height: 3rem;
	width: fit-content;
	top:24%;
	right:80%;
}