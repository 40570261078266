.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	left:5%;
	top: 50%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	right:3%;
	top: 45%;
}

.scenario_image_1{
	height: 3rem;
	width: fit-content;
	top: 25%;
	left:22%;
}
.scenario_image_2{
	height: 3rem;
	width: fit-content;
	top: 40%;
	right:40%;
}