.shieldWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%; 
    overflow: hidden;
    .shield {
        position: relative;
        background-image: url('./bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        aspect-ratio: 1;
        max-width: 80%; 
        width: auto;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .positionAbsolute {
            position: absolute;
        }
        .shieldPart1{
            top: 8%;
            right: 18%;
            width: 32%;

        }
        .shieldPart2{
            top: 20%;
            right: 26%;
            width: 25%;

        }
        .shieldPart3{
            top: 25%;
            right: 4%;
            width: 24%;
        }
        .shieldPart4{
            top: 32%;
            right: 17%;
            width: 28%;
        }
        .shieldPart5{
            top: 58%;
            right: 7%;
            width: 28%;
        }
        .shieldPart6{
            top: 53%;
            right: 20%;
            width: 27%;
        }
        .shieldPart7{
            bottom: 7%;
            right: 32%;
            width: 35%;
        }
        .shieldPart8{
            bottom: 17%;
            right: 35.5%;
            width: 28%;
        }
        .shieldPart9{
            bottom: 10%;
            left:9%;
            width: 27%;
        }
        .shieldPart10{
            bottom: 20%;
            left: 20.5%;
            width: 28%;
        }
        .shieldPart11{
            bottom: 36%;
            left: 6%;
            width: 24%;
        }
        .shieldPart12{
            bottom: 39.5%;
            left: 19%;
            width: 27%;
        }
        .shieldPart13{
            top: 9%;
            left: 18%;
            width: 30%;
        }
        .shieldPart14{
            top: 19%;
            left: 25%;
            width: 26%;
        }
        .circle{
            width: 15%;
            height: 15%;
            margin-top: 2%;
            margin-left: 1%;
            border-radius: 50%;
            background-color: #2A2A2A;
            z-index: 1;
        }
        
    }
}



