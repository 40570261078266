.report {
	display: flex;
	flex-direction: column;
	height: 75dvh;
	width: 100%;
	// justify-content: center;
	// align-items: center;
	// overflow: auto;
	padding: 2rem 0rem;
	gap: 1rem;
	h1 {
		margin: 0;
	}
	.green {
		background-color: #d6e5c7;
	}
	.green2 {
		background-color: #eff4e9;
	}
    .tableContainer{
        height: 100%; 
        overflow: auto;
        display: flex;
        flex-direction: column;
    }
    thead {
        position: sticky;
        top: 0;
        background-color: white; /* Mantém o fundo do cabeçalho */
        z-index: 1;
    }
	.table {
		// height: 100%;
		overflow: auto;
        tbody{
            height: 100%;
            overflow: auto;
        }
	}
	.tr {
		text-align: center;
		height: fit-content;
		td {
			height: fit-content;
            
		}
	}
	.btn {
		width: fit-content;
		cursor: pointer;
		font-family: 'Exo 2', sans-serif;
		font-weight: bold;
		font-size: 0.8rem;
		color: white;
		padding: 0.5rem 2rem;
		border: none;
		background-color: #18673d;
		border-bottom: 0.3rem solid #64c72a;
		border-radius: 0.5rem;
		// max-height: 20%;
	}
	.btn:hover {
		background-color: #64c72a;
		border-bottom: 0.3rem solid #18673d;
	}
	.avarage {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		.headerItems {
			display: flex;
			width: 100%;
			gap: 1rem;
			justify-content: space-around;
			.summary {
				display: flex;
				flex-direction: column;
				width: 45%;
				.header {
					display: flex;
					flex-direction: column;
					.reportItem {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 0.3rem 1rem;
						h3 {
							margin: 0;
						}
					}
				}
			}
			.filter {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				justify-content: center;
				.select {
					display: flex;
					gap: 0.5rem;
					align-items: center;
					justify-content: flex-end;
					select {
						background-color: #ABC88D;
						border: none;
						border-radius: 0.4rem;
						padding: 0.5rem;
						width: calc(70% - 1rem);
					}
					input{
						background-color: #ABC88D;
						border: none;
						border-radius: 0.4rem;
						padding: 0.5rem;
						width: 70%;
						color: black;
					}
					input::placeholder {
						color: black; /* Substitua #ff0000 pela cor desejada */
						opacity: 1; /* Define a opacidade do placeholder (1 é totalmente opaco) */
					}
					h4 {
						margin: 0;
						text-align: end;
					}
				}
			}
		}
	}
	.loading {
		display: flex;
		align-self: center;
		justify-self: center;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}
