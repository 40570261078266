.congradulations {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #18673d;
    color: white;
    font-family: 'Exo 2', sans-serif;
    font-style: italic;
    position: relative;
    
    .shield {
        height: 100%;
        width: 100%;
    }
    h1, h4{
        margin: 0;
        padding: 0;
    }
    .logo {
        background: white;
        padding: 1rem !important;
        border-radius: 0.5rem;
        margin:1rem 0px;
    }
    .confettiWrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none; // Para garantir que os confetes não interfiram na interação com outros elementos
    }
}
