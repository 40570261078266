.shieldPage {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: var(--warning-900);
    justify-content: center;
    align-items: flex-start;
    .wrapper{
	display: flex;

        width: 80%;
        height: 100%;
        .missions {
            width: 50%;
            height: 100%;
        }
        .shield {
            width: 80%;
            height: 80%;
        }
    }
	
}
.accomplished {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	grid-gap: 0.4rem;
	width: calc(100% - 4rem);
	height: calc(80% - 4rem);
	justify-content: flex-start;
	overflow-y: auto;
	padding: 2rem;
	.step {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		aspect-ratio: 1/1;
		background-color: #392510;
		border-radius: 1rem;
		padding: 1rem;
		cursor: auto;
		transition: transform 0.2s ease-out;

		.hexagon {
			position: relative;
			height: 100%;
			width: 100%;
			clip-path: polygon(
				50% 0%,
				100% 25%,
				100% 75%,
				50% 100%,
				0% 75%,
				0% 25%
			);
			overflow: hidden;
			background-color: #146E38;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				height: 100%;
				width: auto;
				transform: translate(-50%, -50%);
				object-fit: cover;
			}

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				height: 60%;
				width: auto;
				transform: translate(-50%, -50%);
				object-fit: cover;
			}
		}
	}
}
