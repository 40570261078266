.timer {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: start;
    height: 2rem;
    border-radius: 0 12px 12px 0px;
    top: 5%;
    left: 0px;
    font-weight: bold;
    color: white;
    font-size: 1.2rem;
    position: absolute;
    background-color: #64C72A;
    padding: 0.5rem 1rem 1rem 0.4rem; /* Aumentar o padding para melhorar a estética */
    max-width: 1.5rem; /* Iniciar com max-width 0 para invisibilidade */
    overflow: hidden; /* Esconde o conteúdo que excede max-width */
    transition: max-width 0.3s ease-in-out; /* Transição suave para max-width */
    white-space: nowrap; /* Impede a quebra de linha */
  }
  
  .timer.open {
    max-width: 200px; /* Define um max-width para quando estiver visível */
  }
  .clock{
    margin-top: 0.4rem;
    max-height: 100%!important;
    max-width: 2rem!important;
  }