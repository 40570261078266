.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	right: 42.5%;
	top: 47%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	left: 37%;
	bottom: 37%;
}
.switch {
	display: flex !important;
	flex-wrap: wrap;
	max-width: 100%;
	gap: 0px !important;
}
.text {
	color: white;
	font-size: 0.8rem;
}
.switchStyle {
	transform: rotate(270deg);
	svg {
		transform: rotate(90deg);
		*{
			color: black !important;
		fill: black !important;
		}
	}
}
.scenario_image_1 {
	height: 3rem;
	width: fit-content;
	top: 35%;
	right: 19%;
}
