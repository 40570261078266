.scenario_lock_1 {
	height: 3rem;
	width: fit-content;
	right:23.5%;
	top: 42%;
}
.scenario_lock_2 {
	height: 3rem;
	width: fit-content;
	right:0%;
	bottom: 34%;
}

.scenario_image_1{
	height: 3rem;
	width: fit-content;
	top: 38%;
	left:6%;
}
.scenario_image_2{
	height: 3rem;
	width: fit-content;
	top: 18%;
	right:48.5%;
}