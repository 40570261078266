.header {
	height: 100%;
	display: flex;
	align-items: center;
	font-family: 'Exo 2', sans-serif;
	font-optical-sizing: auto;
	font-weight: 200;
	font-style: italic;
	color: var(--gray-50);
	align-items: center;
	justify-content: space-between;
	margin: 2rem;
	width: 80%;
	align-self: center;
	gap: 1rem;
	&.mobile {
		margin: 0.5rem;
		margin-top: 1rem;
		height: fit-content;
		font-size: 0.5rem !important;
        .shield {
            gap: 0.5rem;
            .scoreWrapper {
                padding: 0.3rem 1.5rem;
    
                font-size: 1rem !important;
            }
             
            svg{
                max-height: 2rem;
            }
        }
        .logo {
            max-height: 3rem;
            width: auto;
        }
        .userData{
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 100%;
            overflow: hidden;
            gap: 1rem;
            img {
                object-fit: cover;
                max-height: 2rem;
                max-width: 2rem;
                border-radius: 0.5rem;
            }
            .userName{
                // text-wrap:  nowrap;
                font-weight: bold;
                background-color: #6C5338;
                padding: 0.5rem 0.5rem;
                border-radius: 0.2rem;
            }
        }
	}
    .icons{
        cursor: pointer;
    }
    .icons:hover{
        transform: scale(1.1);

    }
    .logffWrapper{
        display: flex;
        gap: 1.5rem;
    }
   
	.shield {
        cursor: pointer;

		display: flex;
		gap: 1.5rem;
		align-items: center;

		.scoreWrapper {
			background-color: var(--gray-950);
			padding: 0.5rem 3rem;
			border-radius: 0.5rem;
		}
	}
	.logo {
		height:80%;
        cursor: pointer;
	}
 
	.userData {
		display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        overflow: hidden;
        gap: 1rem;
		img {
            object-fit: cover;
			height: 60%;
			aspect-ratio: 1/1;
            border-radius: 1rem;
		}
        .userName{
            user-select: none;
            // font-weight: bold;
            background-color: #6C5338;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            font-size: 1.2rem;
        }
	}
    h4{
        font-size: 1.2rem;
    }
}
