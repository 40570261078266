.backdrop {
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw !important;
	height: 100vh !important;
	top: 0;
	left: 0;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.8);
	.modal {
		width: 40%;
		// height: 60%;
		padding: 2rem;
		z-index:999;
		background: white;
		border-radius: 1rem;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		position: relative;
		gap: 1rem;
		font-family: 'Exo 2', sans-serif;
		.body{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.red{
				color: red;
			}
			.form {

				width: 100%;
				max-width: 400px;
				// padding: 20px;
				img {
					max-width: 100%;
				}
				.label {
					margin-bottom: 1rem;
					font-weight: 500;
					color: var(--gray-700);
				}
		
				.input {
					width: 100%;
					padding: 8px;
					margin-bottom: 16px;
					border: 1px solid var(--gray-300);
					border-radius: 4px;
					font-size: 14px;
					color: var(--gray-800);
					box-sizing: border-box;
				}
		
				.button {
					width: 100%;
					padding: 12px;
					border: none;
					background-color: #18673d;
					color: white;
					font-size: 16px;
					font-weight: 500;
					border-radius: 4px;
					cursor: pointer;
					transition: background-color 0.3s ease;
				}
		
				.button:hover {
					background-color: #238f55;
				}
			}
		}
		.closeModal {
			font-size: 1.3rem;
			position: absolute;
			top: 1rem;
			right: 1rem;
			color: black;
			cursor: pointer;
		}

		.closeModal:hover {
			color: red;
		}

		.btn {
			width: fit-content;
			padding: 12px;
			border: none;
			background-color: #18673d;
			color: white;
			font-size: 16px;
			font-weight: 500;
			border-radius: 4px;
			cursor: pointer;
			transition: background-color 0.3s ease;
		}

		.btn:hover {
			background-color: #238f55;
		}
	}
}
