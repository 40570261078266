@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');

.home {
	// background-color: var(--warning-900);
	width: 100dvw;
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	background-image: url('../../../public/menu/menuBg.png');
	background-position: center;
	background-size: 100% 100%; /* Faz a imagem cobrir 100% da altura */
	background-position: center; /* Centraliza a imagem */
	background-repeat: no-repeat;
	overflow-x: hidden;
	overflow-y: hidden;
	.wrapper {
		display: flex;
		flex-direction: column;
		justify-self: self-start;
		position: relative;
		justify-content: space-between;
		height: calc(100% - 15dvh);
		padding: 0px 5rem;
		width: calc(80% - 5rem);
		background-color: rgba(255, 255, 255, 0.8);
		
	}
	.headerWrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--warning-900);
		max-height: 15dvh;
	}
}
.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100dvw;
	max-height: 100dvh;
}
