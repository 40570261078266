.quiz {
	display: flex;
	justify-content: center;
	height: calc(100% - 2rem);
	width: calc(100% - 2rem);
	background-color: #18673d;
	padding: 1rem;
	font-family: 'Exo 2', sans-serif;
	// overflow: hidden;
	.quizBody {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 80%;
		height: 80%;
		background-color: white;
		border-radius: 2rem;
		overflow: auto;
		.header {
			display: flex;
			align-self: center;
			width: 80%;
			object-fit: cover;
			max-height: 20%;
			margin-top: 1rem;
			border-radius: 1rem;
			overflow: hidden;
			background-color: rgb(73, 197, 255);
			img {
				width: 100%;
				height: auto;
				object-fit: cover;
				object-position: center;
			}
		}
		.question {
			display: flex;
			align-self: center;
			justify-content: center;
			width: 80%;
			height: 20%;
			object-fit: scale-down;
			span {
				align-self: center;
				color: #18673d;
				font-size: 1.15rem;
			}
		}
		.alternativesWrapper {
			display: flex;
			flex-direction: row;
			// align-items: center;
			justify-content: center;
			align-self: center;
			height: 60%;
			width: 80%;
			padding: 0.6rem;
			gap: 1rem;
			overflow: auto;
			.alternatives {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				gap: 1rem;
				width: 100%;
				overflow: auto;
				.alternative {
					cursor: pointer;
					flex: 1 1 calc(35% - 1rem);
					margin: 5px;
					text-align: left;
					box-sizing: border-box;
					font-family: 'Exo 2', sans-serif;
					font-size: 1.15rem;
					color: white;
					padding: 0.5rem 2rem;
					border: none;
					background-color: #64c72a;
					border-bottom: 0.3rem solid #18673d;
					border-radius: 0.5rem;
					&.selected {
						background-color: #18673d;
					}
				}
				.alternative:hover {
					background-color: #18673d;
					border-bottom: 0.3rem solid #64c72a;
				}
			}
			.btn {
				align-self: center;
				height: fit-content;
				cursor: pointer;
				font-family: 'Exo 2', sans-serif;
				font-weight: bold;
				font-size: 1.2rem;
				color: white;
				padding: 0.5rem 2rem;
				border: none;
				background-color: #18673d;
				border-bottom: 0.3rem solid #64c72a;
				border-radius: 0.5rem;
			}
			.btn:hover {
				background-color: #64c72a;
				border-bottom: 0.3rem solid #18673d;
			}
		}
	}
	&.mobile {
		height: calc(100% - 0.5rem);
		width: calc(100% - 0.5rem);
		padding: 0.5rem;
		.quizBody {
			gap: 0.2rem;
			.header {
				height: 4rem;
				margin-top: 0.2rem;
				border-radius: 1rem;
				.img {
					width: 100%;
					height: 100%;
				}
			}
		}
		.question {
			font-size: 0.6rem;
		}
		.alternativesWrapper {
			.alternatives {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				gap: 0.1rem;
				width: 100%;
				.alternative {
					font-size: 0.5rem;
					padding: 0.2rem 1rem;
					border-bottom: 0.3rem solid #18673d;
					border-radius: 0.5rem;
				}
				.alternative:hover {
					border-bottom: 0.2rem solid #64c72a;
				}
			}
			.btn {
				font-size: 0.5rem;
				padding: 0.5rem 2rem;
				border-bottom: 0.3rem solid #64c72a;
				border-radius: 0.5rem;
			}
			.btn:hover {
				background-color: #64c72a;
				border-bottom: 0.3rem solid #18673d;
			}
		}
	}


	
}

.loading{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100dvw;
	height: 100dvh;
	.spin{
		display: flex;
		height: 100%;
		align-self: center;
		justify-self: center;
	
	}
}